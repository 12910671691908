// import config from '@/config'
import download from './download'
// const { plugin } = config

export default (Vue) => {
  // for (let name in plugin) {
    // const value = plugin[name]
    // Vue.use(require(`./${name}`).default, typeof value === 'object' ? value : undefined)
    Vue.prototype.$download = download
  // }
}
// export default {
//   install(Vue) {
//     // 下载文件
//     Vue.prototype.$download = download
//   }
// }
