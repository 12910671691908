<template>
    <div style="height:100%;background:#fff;">
        <div style="overflow:hidden;">
            <div class="loginLeft">
                <img src="../../assets/images/loginBg.png" alt="" style="width:700px;margin-top:100px;" >
            </div>
            <div class="loginRight">
                <div class="loginCont">
                    <div style="overflow:hidden;">
                        <img src="../../assets/images/logo@2x.png" alt="" style="float:left;">
                        <p style="margin-bottom:5px;">智慧轻居运维系统</p>
                    </div>
                    <div style="margin-top:30px;">
                        <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" class="demo-ruleForm">
                            <el-form-item label="手机号" prop="mobile">
                                <el-input type="text" prefix-icon="iconfont icon-yonghu" v-model="loginForm.mobile" style="font-size:15px;" @focus="phoneFocus" @blur="phoneBlur" @input="phoneChange" autocomplete="off" @keyup.enter.native="getCode()" @submit.native.prevent></el-input>
                                <div v-if="showHistory && historyPhones.length" style="border: 1px solid #ddd;font-size: 12px;overflow: hidden;padding: 10px 31px;">
                                    <p class="phoneItem" v-for="item in historyPhones" :key="item" @mousedown="thisPhone(item)" style="font-size: 14px;cursor: pointer;float:none;line-height: 20px;">{{item}}</p>
                                    <p @mousedown="clearAll" style="float: right; color: #666; font-size: 12px;cursor: pointer;">清除所有记录</p>
                                </div>
                            </el-form-item>
                            <el-form-item label="验证码" prop="code" style="">
                                <el-input prefix-icon="iconfont icon-mima" v-model="loginForm.code" autocomplete="off" style="width:50%;" @keyup.enter.native="submitForm('loginForm')" @submit.native.prevent></el-input>
                                <!--<el-button type="primary"  style="float:right;"  @click="getCode()">获取验证码</el-button>-->
                                <el-button type="primary"  style="float:right;" :disabled="countdown > 0" @click="getCode()">
                                    {{ countdown > 0 ? `${countdown} 秒后重新获取` : '获取验证码' }}
                                </el-button>
                            </el-form-item>
                            <el-checkbox v-model="loginCheck">当天免登录</el-checkbox>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('loginForm')"  :loading="loginLoading" style="margin-top:30px;width:100%;">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

            </div>
        </div>
        <div >
            <div style="text-align: left;margin-left:200px;display:inline-block">
                <p>联系地址：四川省成都市天府新区天府大道南段2039号天府菁蓉大厦1005</p>
                <p>联系电话：4000072868</p>
                <p>备案/许可证号：蜀ICP备19030836号</p>
            </div>
            <img src="../../assets/images/code.png" alt="" style="float:right;margin-right:200px;">
        </div>
    </div>
</template>

<script>
    import  {Login,Code} from './login'
    export default {
        name: "Login",
        data() {
            return {
                loginCheck:false, //免登录状态
                loginLoading:false, //登录按钮loading状态
                loginForm: {
                    mobile: '',
                    code: '',
                },
                rules: {
                    mobile: [
                        {required: true, message: "请输入正确的手机号", pattern: /^1[3456789]\d{9}$/, trigger: "blur",},
                    ],
                    code: [
                        {required: true, message: "请输入验证码", trigger: "blur"},
                    ],
                },
                countdown:0, //获取验证码倒计时初始时间

                //  手机号缓存
                phone:"",
                showHistory:false,
                historyPhones:[],
            };
        },
        mounted() {
            let list = localStorage.getItem("historyPhones")
                ? JSON.parse(localStorage.getItem("historyPhones"))
                : [];
            this.historyPhones = list.splice(0,5)
            console.log('hois',this.historyPhones)
        },
        methods: {
            //获取验证码
            getCode(){
                let myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{8}$/
                if(!myreg.test(this.loginForm.mobile)){
                    Code(this.loginForm.mobile).then((result) => {
                        if (result["data"].code == 0) {
                            this.$message({
                                message: result["data"].message,
                                type: 'success'
                            });
                            // startTimer() {
                            this.countdown=60
                                const interval = setInterval(() => {
                                    if (this.countdown > 0) {
                                        this.countdown--
                                    } else {
                                        clearInterval(interval)
                                    }
                                }, 1000)
                            // }
                        }else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    })
                }else {
                    this.$message({
                        message: '请输入正确的手机号',
                        type: 'error'
                    });
                }

            },
            submitForm(formName) {
                console.log('login',this.loginCheck)
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log('账号',this.loginForm.mobile)
                        let curIndex= this.historyPhones.indexOf(this.loginForm.mobile);//先判断原本的历史手机号码是否存在
                        console.log('indes',curIndex)
                        if(curIndex>-1){
                            //如果存在，把它放到最前面
                            this.historyPhones.splice(curIndex, 1);
                            this.historyPhones.unshift(this.loginForm.mobile);
                        }else {
                            //如果不存在，添加在历史手机号码数组第一位
                            this.historyPhones.unshift(this.loginForm.mobile);
                        }
                        this.historyPhones =this.historyPhones.splice(0,5);//只取五条数据，不建议展示过多
                        console.log('phones',this.historyPhones)
                        localStorage.setItem("historyPhones", JSON.stringify(this.historyPhones));
                        this.userlogin()
                        /*checkLogin(this.loginForm.mobile).then((result)=>{
                            if (result["data"].code == 0){
                                this.loginLoading=true
                                if(result.data.data==true){
                                    this.$confirm('是否强制登录，已登录用户会被强制下线！', '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    }).then(() => {
                                        this.userlogin()
                                    }).catch(() => {
                                        this.loginLoading=false
                                    });
                                }else {
                                    this.userlogin()
                                }
                            }else{
                                // proxy.$message.error(result["data"].data)
                                this.$message({
                                    message: result["data"]["message"],
                                    type: 'error'
                                });
                            }
                        }).catch(()=>{
                            this.$message({
                                message: '请求失败！',
                                type: 'error'
                            });
                        })*/

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            phoneChange() {
                if (this.phone.length < 11) {
                    this.showHistory = true;
                } else {
                    this.showHistory = false
                }
            },
            phoneFocus(){
                this.showHistory = true
            },
            phoneBlur() {
                this.showHistory = false
            },
            thisPhone(phoneNum){
                this.loginForm.mobile = phoneNum;
                this.$nextTick(()=>{
                    this.showHistory = false;
                });
            },
            clearAll(){
                localStorage.removeItem("historyPhones");
                this.historyPhones = [];
            },

            userlogin(){
                Login(this.loginForm.mobile,this.loginForm.code).then((result)=>{
                    if (result["data"].code == 0){
                        this.loading=false
                        this.$message({
                            message: '登录成功！',
                            type: 'success'
                        });
                        //mqtt监听，用来监听同账号多点登陆，暂时用不上
                        /*result["data"]["extra"]["mqttTopicNum"]?window.connection.doLoginSubscribeUpdate(result["data"]["extra"]["mqttTopicNum"]):null
                        this.$cookies.set("mqttTopicNum", result["data"]["extra"]["mqttTopicNum"])*/
                        this.$cookies.set("access_token", result["data"]["data"]["access_token"])
                        this.$cookies.set("nickName", result["data"]["extra"]["nickName"])
                        this.$cookies.set("mobile", result["data"]["extra"]["mobile"])
                        this.$cookies.set("userType", result["data"]["extra"]["userType"])

                        this.$cookies.set("loginCheck", this.loginCheck)
                        sessionStorage.setItem('keyPath', '/');
                        this.$cookies.set("betaType", "酒店")
                        console.log('cokkie',this.$cookies.get("access_token"))
                        console.log('userType',this.$cookies.get("userType"))
                        // console.log('asscesstoken',localStorage.getItem("access_token"))
                        this.$router.push('/')
                    }else{
                        this.loginLoading=false
                        // proxy.$message.error(result["data"].data)
                        this.$message({
                            message: result["data"]["message"],
                            type: 'error'
                        });
                    }
                }).catch(()=>{
                    this.$message({
                        message: '请求失败！',
                        type: 'error'
                    });
                })
            },

            /*//连接服务器
            connect(topic) {
                console.log('topic',topic)
                var serveType=false   //订阅topic的服务器，true------生产，false-------测试
                var option = {
                    "ServerUri":serveType ? "mqtt-dev-esp8266-v2.baiyatech.com" : "mqtt-test.baiyatech.cn",
                    "ServerPort": 8084,
                    "UserName": serveType ? "baiya" : "admin",
                    "Password": serveType ? "baiyatech" : "public",  //"password",
                    "ClientId": "",
                    "TimeOut": 5,
                    "KeepAlive": 60,
                    "CleanSession": true,
                    "SSL": true
                };
                console.log('option',option)
                this.client = mqtt.connect('mqtt-dev-esp8266-v2.baiyatech.com:8084',option);
                this.client.on("connect", (e) => {
                    console.log("成功连接服务器:", e);
                    //订阅
                    this.client.subscribe(topic,
                        (error)=> {
                            if (!error) {
                                console.log("订阅成功");
                            } else {
                                console.log("订阅失败");
                            }
                        }
                    );
                });
                //重新连接
                this.reconnect();
                //是否已经断开连接
                this.MqError();
                //监听获取信息
                this.getMessage();
            },
            //监听接收消息
            getMessage() {
                this.client.on("message", (topic, message) => {
                    if (message) {
                        const res = JSON.parse(message.toString());
                        console.log(res, "收到数据");
                    }
                });
            },
            //监听服务器是否连接失败
            MqError() {
                this.client.on("error", (error) => {
                    console.log("连接失败:", error);
                    this.client.end();
                });
            },
            //断开连接
            endConnect() {
                this.client.end();
                this.client =null;
                console.log("服务器已断开连接!");
            },
            //监听服务器重新连接
            reconnect() {
                this.client.on("reconnect", (error) => {
                    console.log("正在重连:", error);
                });
            },*/
        }
    }
</script>

<style scoped>
    .loginLeft {
        width:60%;
        float:left;
        text-align: center;
    }
    .loginRight {
        width:40%;
        float:right;
    }
    .loginCont {
        width:50%;
        height:300px;
        margin:200px auto;
    }
    .loginCont p {
        float:left;
        font-size: 30px;
    }
    ::v-deep .el-form-item__label{
        width: 100%;text-align: left;
    }
    .phoneItem:hover{
        color:#1772f6
    }
</style>
