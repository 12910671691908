import request from '../../utils/request'
// const token=localStorage.getItem("access_token");
// console.log('token',token)


//根据查询条件获取酒店列表
export function hotelList(contactNumber,contacts,hotelName,limit,page,token) {
    // const token=localStorage.getItem("access_token");
    console.log('token',token)
    return request({
        url:"/hotel/hotel/getHotelList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            contactNumber:contactNumber,
            contacts:contacts,
            hotelName:hotelName,
            limit:limit,
            page:page,
        }
    })
}

//新增酒店
export function addHotel(data,token){
    return request({
        url:"/hotel/hotel/addHotel",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}

//获取酒店详情
export function hotelDetail(hotelId,token){
    return request({
        url:"/hotel/hotel/getHotelDetails",
        method:"GET",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId
        }
    })
}
//修改酒店
export function editHotel(hotelName,hotelAddr,contacts,contactNumber,hotelId,token){
    return request({
        url:"/hotel/hotel/updateHotel",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelName: hotelName, //酒店名称
            hotelAddr: hotelAddr, //酒店地址
            contacts: contacts, //联系人
            contactNumber: contactNumber, //联系方式
            hotelId:hotelId
        }
    })
}
//查询酒店下是否有房间
export function isRoom(hotelId,token){
    return request({
        url:"/hotel/hotel/checkExistsHotel",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId
        }
    })
}
//删除酒店
export function delHotel(hotelId,token){
    return request({
        url:"/hotel/hotel/removeHotel",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId
        }
    })
}

//查询红外码库
export function codeList(hotelId,productType,token) {
    // const token=localStorage.getItem("access_token");
    console.log('token',token)
    return request({
        url:"/hotel/getIrCodeLibrary",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
            productType:productType,
        }
    })
}

//新增编辑红外码库
export function addCode(data,token){
    console.log('data',data)
    return request({
        url:"/hotel/saveOrUpdateIrCodeLibrary",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        data:data
    })
}
