<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
export default {
    name: 'app',
    components: { },
    data() {
        return {
            gap_time: 0,
            beforeUnload_time: 0,
        }
    },
    created() {
        //mqtt监听，用来监听同账号多点登陆，暂时用不上
        // window.connection.open((res)=>{
        //     const mqttTopicNum = this.$cookies.get('mqttTopicNum')
        //     //链接 mqtt
        //     console.log('mqtt',mqttTopicNum)
        //     if (res && mqttTopicNum){
        //         window.connection.doLoginSubscribeUpdate(mqttTopicNum)
        //     }
        // })
    },
    mounted() {
        // 监听浏览器关闭
        console.log('check',this.$cookies.get("loginCheck")==='false')
        // if(this.$cookies.get("loginCheck")==='false') {
        window.addEventListener("beforeunload", () => this.beforeunloadHandler());
        window.addEventListener("unload", () => this.unload());
        // }

        window.receiveMsg = this.receiveMsg
    },
    destroyed() {
        // 移除监听
        window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
        window.removeEventListener("unload", () => this.unload());
    },
    methods:{
        // 关闭窗口之前执行
        beforeunloadHandler() {
            alert(this.$cookies.get("loginCheck")==='false')
            this.beforeUnload_time = new Date().getTime();
        },
        unload() {
            this.gap_time = new Date().getTime() - this.beforeUnload_time;
            //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
            alert('time')
            if (this.gap_time <= 5) {
                if(this.$cookies.get("loginCheck")==='false'){
                    this.$cookies.set("access_token",'')
                }
                // this.checkout() // 退出登录接口 这里应当换为个人的登出方法
            } else {
                return true
            }
        },
        /**
         * 接收mqtt消息
         * @param message
         */
        async receiveMsg(message) {
            console.log('message',message)
            if (message.outLogMessage!=''){
                this.$confirm(message.outLogMessage, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$cookies.set("access_token",'')
                    this.$cookies.set("nickName", '')
                    this.$cookies.set("mobile", '')
                    this.$cookies.set("userType", '')
                    this.$cookies.set("mqttTopicNum", '')
                    this.$router.replace({path: "/login"})
                })
                // const alert = await alertController.create({
                //     header: '提示',
                //     message: message.outLogMessage,
                //     backdropDismiss: false,
                //     buttons: [
                //         {
                //             text: '确定',
                //             handler: () => {
                //                 //清空
                //                 removeCache()
                //                 this.$router.replace({path: "/login"});
                //             }
                //         }
                //     ]
                // });
                // await alert.present()
            }
        }

    }
}
</script>

<style>
</style>
