import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import store from './store'
import axios from "axios"
import './plugins/element.js'
import './assets/css/common.css'
import router from './router'
import * as echarts from 'echarts'
import installPlugin from '@/plugins'
// import * from './connection'
Vue.use(VueCookies)

Vue.config.productionTip = false
Vue.prototype.$axios=axios
Vue.prototype.$echarts=echarts

installPlugin(Vue)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
