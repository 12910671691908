<template>
    <div style="height: 95%;position:relative;background: #fff; padding: 20px;border-radius: 15px;">
        <!--搜索区域-->
        <div class="header">
            <div style="">
                酒店名称：
                <el-input v-model="hotelName" size="medium" placeholder="请输入内容" @keyup.enter.native="GetHotelList" @submit.native.prevent></el-input>
            </div>
            <div style="">
                创建人：
                <el-input v-model="contacts" size="medium" placeholder="请输入内容" @keyup.enter.native="GetHotelList" @submit.native.prevent></el-input>
            </div>
            <div style="">
                联系方式：
                <el-input v-model="contactNumber" size="medium" placeholder="请输入内容" @keyup.enter.native="GetHotelList" @submit.native.prevent></el-input>
            </div>
            <!--<div style="color:#409EFF">-->
                <!--小度项目ID：-->
                <!--<el-input v-model="shopId" size="medium" placeholder="请输入内容"></el-input>-->
            <!--</div>-->
            <el-button type="primary" icon="el-icon-search" @click="GetHotelList()">搜索</el-button>
            <el-button v-if="userType==3" type="primary" icon="el-icon-circle-plus-outline" @click="addUserfun">新增</el-button>
        </div>
        <div class="wrapper">
            <el-table :data="tableData" style="width: 100%" v-loading="loading">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="hotelName" label="酒店名称" width="150"></el-table-column>
                <el-table-column prop="hotelAddr" label="酒店地址" width="150"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
                <el-table-column prop="contacts" label="创建人" width="120"></el-table-column>
                <el-table-column prop="contactNumber" label="联系方式" width="120"></el-table-column>
                <!--<el-table-column prop="shopId" label="小度项目ID" width="200"></el-table-column>-->
                <el-table-column prop="status" label="状态" width="80">
                    <template scope="scope">
                        <span v-if="scope.row.status===0">未配置</span>
                        <span v-else-if="scope.row.status===1">配置中</span>
                        <span v-else-if="scope.row.status===2">配置完成</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作">
                    <template slot-scope="scope">
                        <div class="operate">
                            <span @click="userMange(scope)" style="cursor : pointer;">人员管理</span>
                            <span @click="toRoom(scope)" style="cursor : pointer;">房间管理</span>
                            <span @click="deviceMange(scope)" style="cursor : pointer;">设备管理</span>
                            <span v-if="false" @click="codeMange(scope.row.hotelId)" style="cursor : pointer;">码库管理</span>
                            <span @click="handleEdit(scope)" style="cursor : pointer;">编辑</span>
                            <span @click="handleDelete(scope)" style="cursor : pointer;" v-if="userType==3">删除</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <Mypage :total="total" :pageSize="limit" @changePage="changePage"/>

        <!--增加编辑对话框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="25%" :close-on-click-modal="false" :before-close="resetForm">
            <el-form :model="hotelForm" status-icon :rules="rules" ref="hotelForm" class="demo-ruleForm">
                <el-form-item label="酒店名称" prop="hotelName">
                    <el-input type="text" v-model="hotelForm.hotelName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="酒店地址" prop="hotelAddr">
                    <el-input type="text" v-model="hotelForm.hotelAddr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="创建人" prop="contacts">
                    <el-input type="text" v-model="hotelForm.contacts" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="contactNumber">
                    <el-input type="text" v-model="hotelForm.contactNumber" autocomplete="off"></el-input>
                </el-form-item>
                <!--<el-form-item label="小度项目ID" prop="shopId">-->
                    <!--<el-input type="text" v-model="hotelForm.shopId" autocomplete="off"></el-input>-->
                <!--</el-form-item>-->
                <el-form-item style="text-align: right">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitHotelMsg('hotelForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <!--码库管理对话框-->
        <el-dialog title="码库管理" :visible.sync="codedialogVisible" width="30%" :close-on-click-modal="false">
            <el-form :model="codeForm" status-icon  ref="codeForm" class="demo-ruleForm">
                <el-form-item label="产品类型" prop="productType">
                    <el-select v-model="codeForm.productType" placeholder="请选择"  style="width:230px;" @change="selInfraredDevice()">
                        <el-option label="电视" value="7002"></el-option>
                        <el-option label="风扇" value="7008"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item  style="margin-left:20px;" :label="item.action" v-for="(item,index) in codeForm.irActionCodeList" v-bind:key="item.action" :prop="`irActionCodeList[${index}].irCode`" :rules="irActionCodeListRules.irCode">

                    <el-input type="text" v-model="item.irCode" autocomplete="off"  style="width:300px;" maxlength="160"
                                  onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"   oninput="this.value = this.value.replace(/[^0-9A-F]/g,'')" ></el-input>
                </el-form-item>

                <el-form-item style="text-align: right">
                    <el-button @click="codedialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitCodeMsg('codeForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import Mypage from '../../components/MyPagination.vue'
    import {hotelList,addHotel,hotelDetail,editHotel,isRoom,delHotel,codeList,addCode} from './hotelMange'

    export default {
        components: {
            Mypage
        },
        name: "HotelMange",
        data() {
            return {
                userType:this.$cookies.get("userType"),
                dialogVisible: false, //打开新增模态框
                codedialogVisible:false, //码库管理模态框
                loading:true,
                title:'新增',
                contactNumber: '', //联系方式
                contacts: '',  //创建人
                hotelName: '',
                // shopId: '',
                total:10,
                limit: 12,
                page: 1,
                tableData: [],
                hotelId:'',
                hotelForm: {
                    hotelName: '', //酒店名称
                    hotelAddr: '', //酒店地址
                    contacts: '', //创建人
                    contactNumber: '', //联系方式
                    // shopId: '', //小度项目ID
                },
                rules: {
                    hotelName: [
                        {required: true, message: "请输入酒店名称", trigger: "blur",},
                    ],
                    hotelAddr: [
                        {required: true, message: "请输入酒店地址", trigger: "blur"},
                    ],
                    contacts: [
                        {required: true, message: "请输入创建人", trigger: "blur",},
                    ],
                    contactNumber: [
                        {required: true, message: '请输入正确的联系方式', pattern: /^1[3456789]\d{9}$/, trigger: 'blur'},
                    ],
                    // shopId: [
                    //     {required: true, message: "请输入项目ID", trigger: "blur"},
                    // ],
                },
                codeForm:{ //码库form
                    codeId:'',
                    hotelId:'',
                    productType:'7002',
                    irActionCodeList:[//红外码列表
                        {
                            action:'电源',
                            irCode:''
                        },
                        {
                            action:'音量+',
                            irCode:''
                        },
                        {
                            action:'音量-',
                            irCode:''
                        },
                        {
                            action:'静音',
                            irCode:''
                        },
                        {
                            action:'频道+',
                            irCode:''
                        },
                        {
                            action:'频道-',
                            irCode:''
                        },
                    ],
                },
                irActionCodeListRules:{
                    irCode:[
                        {required: false, message: '请输入正确的十六进制数', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            //表单验证重置
            resetForm(){
                this.dialogVisible=false
                this.codedialogVisible=false
                this.$refs.hotelForm.resetFields()
                this.$refs.codeForm.resetFields()
            },
            //    查询酒店列表
            GetHotelList() {
                console.log('user',this.userType)

                hotelList(this.contactNumber, this.contacts, this.hotelName, this.limit, this.page, this.$cookies.get("access_token")).then((result) => {
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            this.tableData = result["data"]["data"]["records"]
                            this.total = parseInt(result["data"]["data"]["total"])
                        } else {
                            this.$message({
                                message: result.data.message,
                                type: 'error'
                            });
                        }
                        this.loading=false
                    }
                })
            },
            addUserfun(){
                this.dialogVisible = true;
                this.title='新增'
                this.hotelForm= {
                    hotelName: '', //酒店名称
                        hotelAddr: '', //酒店地址
                        contacts: '', //创建人
                        contactNumber: '', //联系方式
                        // shopId: '', //小度项目ID
                }
            },
            //跳转到人员管理
            userMange(scope){
                this.$router.push({
                    path:'./User',
                    query:{
                        hotelId:scope.row.hotelId,
                        hotelName:scope.row.hotelName
                    }
                })
            },
            //跳转到设备管理
            deviceMange(scope){
                this.$router.push({
                    path:'./Operation',
                    query:{
                        hotelId:scope.row.hotelId,
                        hotelName:scope.row.hotelName
                    }
                })
            },
            //码库管理  切换红外设备
            selInfraredDevice(){
              console.log('e',this.codeForm.productType)
                this.getCodeList()

            },
            codeMange(hotelId){
                console.log('hotel',hotelId)
                this.codedialogVisible=true
                this.codeForm.hotelId=hotelId
                this.getCodeList()
            },
            //获取红外码库列表
            getCodeList(){
                codeList(this.codeForm.hotelId,this.codeForm.productType, this.$cookies.get("access_token")).then((result) => {
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            if(result["data"]["data"].irActionCodeList.length!=0){
                                this.codeForm.codeId = result["data"]["data"].codeId
                                this.codeForm.hotelId = result["data"]["data"].hotelId
                                this.codeForm.irActionCodeList = result["data"]["data"].irActionCodeList
                                this.codeForm.productType= result["data"]["data"].productType.toString()
                            }else {
                                 if(this.codeForm.productType==7002){
                                    this.codeForm.irActionCodeList=[//红外码列表
                                        {
                                            action:'电源',
                                            irCode:''
                                        },
                                        {
                                            action:'音量+',
                                            irCode:''
                                        },
                                        {
                                            action:'音量-',
                                            irCode:''
                                        },
                                        {
                                            action:'静音',
                                            irCode:''
                                        },
                                        {
                                            action:'频道+',
                                            irCode:''
                                        },
                                        {
                                            action:'频道-',
                                            irCode:''
                                        },
                                    ]
                                }else if(this.codeForm.productType==7008){
                                    this.codeForm.irActionCodeList=[//红外码列表
                                        {
                                            action:'电源',
                                            irCode:''
                                        },
                                        {
                                            action:'换挡',
                                            irCode:''
                                        },
                                        {
                                            action:'摇头',
                                            irCode:''
                                        },
                                        {
                                            action:'定时',
                                            irCode:''
                                        },
                                    ]
                                }

                            }
                            console.log('codeform',this.codeForm)
                        } else {
                            this.$message({
                                message: result.data.message,
                                type: 'error'
                            });
                        }
                    }
                })
            },
            //提交新增编辑码库
            submitCodeMsg(formName){
                console.log('form',this.codeForm)
                var isEven=true //红外码是否都为双数
                for(var i=0;i<this.codeForm.irActionCodeList.length;i++){
                    console.log('i',this.codeForm.irActionCodeList[i].irCode.length%2==0)
                    if(this.codeForm.irActionCodeList[i].irCode.length%2!=0){
                        this.$message({
                            message: '红外码长度必须是双数！',
                            type: 'error'
                        });
                        isEven=false
                        i=this.codeForm.irActionCodeList.length
                    }
                }
                if(isEven){ //红外码长度都为双数
                    this.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log('form',this.codeForm)
                        addCode(this.codeForm,this.$cookies.get("access_token")).then((result)=>{
                            if(result.data.code==0){
                                this.$message({
                                    message: '编辑成功！',
                                    type: 'success'
                                });
                                this.codedialogVisible = false
                            }else {
                                this.$message({
                                    message: result.data.message,
                                    type: 'error'
                                });
                            }
                        }).catch(()=>{
                            this.$message({
                                message: '请求失败！',
                                type: 'success'
                            });
                            this.codedialogVisible = false
                        })
                    }
                })
                }
            },
            //    编辑
            handleEdit(scope) {
                this.dialogVisible = true
                this.title='编辑'
                hotelDetail(scope.row.hotelId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.hotelForm=result["data"]["data"]
                    }
                })
                this.hotelId=scope.row.hotelId
            },
            //    删除
            handleDelete(scope) {
                isRoom(scope.row.hotelId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        if(result["data"].data==true){
                            this.$confirm('该酒店下存在房间，确认删除吗?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.deletHotel(scope.row.hotelId)
                            }).catch(() => {
                                this.$message({
                                    type: 'info',
                                    message: '已取消删除'
                                });
                            });
                        }else {
                            this.$confirm('确认删除吗?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.deletHotel(scope.row.hotelId)
                            }).catch(() => {
                                this.$message({
                                    type: 'info',
                                    message: '已取消删除'
                                });
                            });
                        }
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            deletHotel(hotelId){
                this.loading=true
                delHotel(hotelId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.GetHotelList()
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                        this.loading=false
                    }
                })
            },
            // 提交酒店模态框数据
            submitHotelMsg(formName) {
                if(this.title=='新增'){
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.loading=true
                            addHotel(this.hotelForm,this.$cookies.get("access_token")).then((result)=>{
                                if(result.data.code==0){
                                    this.$message({
                                        message: '添加成功！',
                                        type: 'success'
                                    });
                                    this.dialogVisible = false
                                    this.GetHotelList();
                                }else {
                                    this.$message({
                                        message: result.data.message,
                                        type: 'error'
                                    });
                                    this.loading=false
                                }
                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'success'
                                });
                                this.dialogVisible = false
                                this.loading=false
                            })
                        }
                    })
                }else if(this.title=='编辑'){
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.loading=true
                            editHotel(this.hotelForm.hotelName,this.hotelForm.hotelAddr, this.hotelForm.contacts,this.hotelForm.contactNumber,this.hotelId,this.$cookies.get("access_token")).then((result)=>{
                                if(result["data"].code==0){
                                    this.$message({
                                        message: '修改成功！',
                                        type: 'success'
                                    });

                                    this.GetHotelList();
                                }else {
                                    this.$message({
                                        message: result["data"].message,
                                        type: 'error'
                                    });
                                    this.loading=false
                                }
                                this.dialogVisible = false
                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'error'
                                });
                                this.dialogVisible = false
                                this.loading=false
                            })
                        }
                    })
                }

            },
        //    分页
            changePage(num){
                this.page=num;
                this.GetHotelList()
            },
        //    跳转到房间页面
            toRoom(scope){
                console.log('name',scope.row.hotelName)
                this.$cookies.set("hotelName", scope.row.hotelName)
                this.$cookies.set("hotelId", scope.row.hotelId)
                sessionStorage.setItem('activePath', '/manage');
                this.$router.push({
                    path:'./Room',
                    // query:{
                    //     hotelName:scope.row.hotelName,
                    //     hotelId:scope.row.hotelId,
                    // }
                })
            }


        },
        created() {  /*生命周期函数*/
                this.GetHotelList();
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
    }

    .el-input {
        width: 200px;
        margin: 0 10px;
    }

    .el-button {
        padding: 10px 20px;
    }

    .wrapper {
        margin-top: 20px;
        text-align: center;
    }

    .operate span {
        color: #276EF1;
        margin: 0 5px;
    }

    ::v-deep .el-table th, ::v-deep .el-table td {
        text-align: center !important;
    }

    ::v-deep .el-form-item__label {
        width: 100px
    }
    ::v-deep .el-dialog__header {
        border-bottom:1px solid #ddd;
    }

</style>
