<template>
    <div style="text-align: center;margin:30px 0;width:100%;">
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="changePage"></el-pagination>
    </div>

</template>

<script>
    export default {
        name: "MyPagination",
        props:{
            total:{
                type:Number,
                default:100
            },
            pageSize:{
                type:Number,
                default:10
            }
        },
        methods:{
            changePage(page){
                console.log('page',page)
                this.$emit('changePage',page)
            }

        }

    }
</script>

<style scoped>
</style>