import request from '../../utils/request'

//查询酒店统计
export function getHotelCount(token) {
    return request({
        url:"/hotel/statistic/count/hotel",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//查询酒店房间统计
export function getRoomCount(token) {
    return request({
        url:"/hotel/statistic/count/room",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//查询酒店设备统计
export function getDeviceCount(token) {
    return request({
        url:"/hotel/statistic/count/device",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//查询在线设备统计
export function getOnlineCount(token) {
    return request({
        url:"/hotel/statistic/count/onlineDevice",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//查询离线设备统计
export function getOutlineCount(token) {
    return request({
        url:"/hotel/statistic/count/offlineDevice",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//酒店设备活跃度查询
export function activityCount(startDate,endDate,hotelName,limit,page,token) {
    return request({
        url:"/hotel/statistic/activity/hotel/count",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            startDate:startDate,
            endDate:endDate,
            hotelName:hotelName,
            limit:limit,
            page:page,
        }
    })
}
//酒店设备活跃度折线图
export function getCharts(startDate,endDate,hotelId,token) {
    return request({
        url:"/hotel/statistic/activity/device/chart",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            startDate:startDate,
            endDate:endDate,
            hotelId:hotelId
        }
    })
}
//房间设备活跃度
export function roomActivityCount(startDate,endDate,hotelId,roomNumber,limit,page,token) {
    return request({
        url:"/hotel/statistic/activity/room/count",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            startDate:startDate,
            endDate:endDate,
            hotelId:hotelId,
            roomNumber:roomNumber,
            limit:limit,
            page:page,
        }
    })
}
