<template>
    <div style="height: 100%">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: "Content"
    }
</script>

<style scoped>

</style>