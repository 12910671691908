import request from '../../utils/request'

//退出登录
export function checkOut(token){
    return request({
        url:"/hotel/logout/token",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{token:token}
    })
}