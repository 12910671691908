import request from '../../utils/request'
// import qs from 'qs'

/**
 * 登录
 * @param userPhone
 * @param password
 * @returns {AxiosPromise}
 * @constructor
 */
export function Code(mobile) {
    return request({
        url:"/hotel/login/getLoginCode",
        method:"get",
        params:{
            mobile:mobile,
            loginPoint:2
        }
    })
}
export function checkLogin(mobile) {
    return request({
        url:"/hotel/login/checkLogin",
        method:"POST",
        params:{
            mobile:mobile,
        }
    })
}
export function Login(mobile,code) {
    return request({
        url:"/hotel/login/mobile",
        method:"POST",
        params:{
            mobile:mobile,
            code:code,
            loginPoint:2
            // loginType:3
        }
    })
}
