import axios from 'axios'
import router from '@/router'

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分 "http://api-test.baiyatech.cn"
    baseURL: "https://api-test.baiyatech.cn",   //测试环境
    // baseURL: "https://api.baiyatech.cn",    //生产环境接口域名
    //     // 超时
    timeout: 30000
})
// 添加请求拦截器
// service.interceptors.request.use(function (config) {
//     //用来区分是使用流域的host还是监控的host，如果是监控的则将host换成对应的
//     if (config.requestBase == "VUE_APP_MONITOR_HOST"){
//         config.baseURL = process.env.VUE_APP_MONITOR_HOST
//     }
//     // 在发送请求之前做些什么
//     return config;
// }, function (error) {
//     // 对请求错误做些什么
//     return Promise.reject(error);
// });
//
// // 添加响应拦截器
// service.interceptors.response.use(function (response) {
//     // 对响应数据做点什么
//     return response;
// }, function (error) {
//     // 对响应错误做点什么
//     return Promise.reject(error);
// });

// response interceptor（接收拦截器）
service.interceptors.response.use(function (response) {
    return response;
}, error => {
    console.log('eror',error.response)
    let response = error.response;
    const status = response.status;
    if (status === 401) {
        console.log('status',status === 401)
        // 判断状态码是401 跳转到登录
        router.replace({ path: "/login" });
    }
    return {
        data: {
            data: '请重新登录',
            error: "请重新登录"
        }
    };
});
export default service
